@mixin row-details {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    width: calc(100vw - 11rem);

    > div, > section {
        flex: 1 1 10rem;
        background-color: #00000005;
        padding: 0.5rem 1rem;

        &:hover {
            background-color: #00000010;
        }
    }
}

@mixin suffix {
    color: #888;
    font-size: 0.8rem;
}

@mixin zero {
    color: #ccc;
}