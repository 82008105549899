@use '@angular/material' as mat;
@include mat.core();
// TODO: https://material.angular.io/guide/theming#application-background-color

// DARK THEME
$ochre-palette: (
  50: #fffde7,
  100: #fff9c4,
  200: #fff59d,
  300: #fff176,
  400: #ffee58,
  500: #E98C00,
  600: #fdd835,
  700: #fbc02d,
  800: #f9a825,
  900: #f57f17,
  A100: #ffff8d,
  A200: #ffff00,
  A400: #ffea00,
  A700: #ffd600,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: rgba(rgb(255, 255, 255), 1),
    600: rgba(black, 0.87),
    700: rgba(black, 0.87),
    800: rgba(black, 0.87),
    900: rgba(black, 0.87),
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: rgba(black, 0.87),
    A700: rgba(black, 0.87),
  )
);

$teal-palette: (
  50: #b2dfdb,
  100: #b2dfdb,
  200: #4db6ac,
  300: #4db6ac,
  400: #cdeaffe4,
  500: #CDEAFF,
  600: #9fd8ff,
  700: #00796b,
  800: #00685b,
  900: #004d40,
  A100: #a7ffeb,
  A200: #64ffda,
  A400: #1de9b6,
  A700: #00bfa5,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(#0094FF, 0.87),
    500: rgba(#0094FF, 1),
    600: rgba(#0069b5, 0.87),
    700: rgba(#004d85, 0.87),
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: rgba(black, 0.87),
    A700: rgba(black, 0.87),
  )
);


$ct-primary: mat.define-palette($ochre-palette, 500, 400, 600); // default lighter darker
$ct-accent: mat.define-palette($teal-palette, A200, A100, A700);

$ct-dark-theme: mat.define-dark-theme((
  color: (
    primary: $ct-primary,
    accent: $ct-accent,
  )
));


// LIGHT THEME
/*
$paletta-név1: {
  50: #???????,
  100: #???????,
  200: #???????,
  300: #???????,
  400: #???????,
  500: #???????,
  600: #???????,
  700: #???????,
  800: #???????,
  900: #???????,
  A100: #???????,
  A200: #???????,
  A400: #???????,
  A700: #???????,
  contrast: (50: #???????,
    100: #???????,
    200: #???????,
    300: #???????,
    400: #???????,
    500: #???????,
    600: #???????,
    700: #???????,
    800: #???????,
    900: #???????,
    A100: #???????,
    A200: #???????,
    A400: #???????,
    A700: #???????,
  )
};

$paletta-név2: {
  50: #???????,
  100: #???????,
  200: #???????,
  300: #???????,
  400: #???????,
  500: #???????,
  600: #???????,
  700: #???????,
  800: #???????,
  900: #???????,
  A100: #???????,
  A200: #???????,
  A400: #???????,
  A700: #???????,
  contrast: (50: #???????,
    100: #???????,
    200: #???????,
    300: #???????,
    400: #???????,
    500: #???????,
    600: #???????,
    700: #???????,
    800: #???????,
    900: #???????,
    A100: #???????,
    A200: #???????,
    A400: #???????,
    A700: #???????,
)};

$ct-light-primary: mat.define-palette($paletta-név1, 500, 400, 600); // default lighter darker
$ct-light-accent: mat.define-palette($paletta-név2, A200, A100, A700);
*/
$ct-theme: mat.define-light-theme((
  color: (
    primary: $ct-primary,
    accent: $ct-accent,
  )
));
