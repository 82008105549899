@use 'variables' as vars;

$statusIndicatorSize: 0.7rem;
.ts,
.ts-status,
.contract,
.contract-status,
.user,
.userstatus,
.some-status {

    // valaki másra kell várnia az aktuális usernek
    &.waiting {

        &::before {
            content: '';
            width: $statusIndicatorSize;
            aspect-ratio: 1;
            background: vars.$waitingBg;
            display: inline-block;
            margin-bottom: -1px;
        }
    }

    &.warning {

        &::before {
            content: '';
            width: $statusIndicatorSize;
            aspect-ratio: 1;
            background: vars.$warningBg;
            display: inline-block;
            margin-bottom: -1px;
        }
    }

    &.success {

        &::before {
            content: '';
            width: $statusIndicatorSize;
            aspect-ratio: 1;
            background: vars.$successBg;
            display: inline-block;
            margin-bottom: -1px;
        }
    }

    &.disabled {
            
            &::before {
                content: '';
                width: $statusIndicatorSize;
                aspect-ratio: 1;
                background: vars.$disabledBg;
                display: inline-block;
                margin-bottom: -1px;
            }
    }

    &.danger {
            
            &::before {
                content: '';
                width: $statusIndicatorSize;
                aspect-ratio: 1;
                background: vars.$dangerBg;
                display: inline-block;
                margin-bottom: -1px;
            }
    }

}

tr.deleted-row {
    color: #ff000073;
}

tr.disabled-row {
    color: #00000073;
}