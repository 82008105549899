@use 'sass:map';
@use '@angular/material' as mat;
@use "crew-time-theme" as ct-theme;
 
$color-config: mat.get-color-config(ct-theme.$ct-theme);
$primary-palette: map.get($color-config, 'primary');
$accent-palette: map.get($color-config, 'accent');

$primary: mat.get-color-from-palette($primary-palette, 500);
$primary600: mat.get-color-from-palette($primary-palette, 600);
$primary400: mat.get-color-from-palette($primary-palette, 400);
$primary-contrast: mat.get-contrast-color-from-palette($primary-palette, 500);
$primary-contrast400: mat.get-contrast-color-from-palette($primary-palette, 400);
$accent: mat.get-color-from-palette($accent-palette, 500);
$accent400: mat.get-color-from-palette($accent-palette, 400);
$accent600: mat.get-color-from-palette($accent-palette, 600);
$accent-contrast: mat.get-contrast-color-from-palette($accent-palette, 500);
$accent-contrast400: mat.get-contrast-color-from-palette($accent-palette, 400);
$accent-contrast600: mat.get-contrast-color-from-palette($accent-palette, 600);
$accent-contrast700: mat.get-contrast-color-from-palette($accent-palette, 700);

// Breakpoints
$xs: 0;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

// Colors
$unimportant-color: #888;
$unimportant-font-size: 0.8rem;

$currency-color: #888;
$currency-font-size: 0.8rem;
$currency-zero-color: #ccc;

$success-color: #e8f5e9;
$success-bg-color: #4caf50;

$warning-color: #C7B300;
$warning-bg-color: #FFFBD2;

$danger-color: #ec0000; //#f44336;
$danger-bg-color: #ffebee;

$info-color: #0094FF;
$info-bg-color: #FFFFFF;

$disabledBg: #bababa;
$disabledColor: #555555;
$deletedBg: #878787;
$deletedColor: #fff;
$warningBg: #E98C00;
$warningColor: #fff;
$waitingBg: #C7B300;
$waitingColor: #fff;
$successBg: #00AE10;
$successColor: #fff;
$dangerBg: #EC0000;
$dangerColor: #fff;
$unknownBg: #794242;
$unknownColor: #fff;

// Grid parameters
:root {
  --Grid-columns: 12;
  --Grid-columnSpacing: 16px;
  --Grid-rowSpacing: 16px;
}


$mobileWidth: 768px;

$fade-bottom-mask: linear-gradient(to bottom, black calc(100% - 48px), transparent 100%);

// MIXINS
@mixin box {
  padding: 1rem;
  cursor: pointer;
  background-color: $accent;
  color: $accent-contrast;
  border-color: $accent-contrast;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;

  &.not-clickable {
      cursor: not-allowed;
      background-color: $disabledBg;
      color: $disabledColor;
      border-color: $disabledBg;
    }
  
  &:hover:not(.not-clickable) {
    background-color: $accent600;
  }

  &.selected {
    background-color: $accent-contrast;
    color: $accent;

    &:hover:not(.not-clickable) {
      background: #0029ff;
    }
  }
}

@mixin error-box {
  @include box;
  background-color: $danger-bg-color;
  color: $danger-color;
  border-color: $danger-color;
}